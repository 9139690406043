// src/pages/Welcome.js
import React from "react";
import { Route, Routes, Link, useLocation } from "react-router-dom";

function Welcome() {
    return (
        <div className="text-left h-screen flex flex-col px-6 justify-center items-center text-center">
            <p className="font-sans mb-4 tracking-[10px] font-extralight text-[#a300ff] text-center">
                TOGETHER WITH THEIR FAMILIES
            </p>
            <h1 className="text-7xl font-bold mb-4 text-[#476a4c] mb-[50px]">Luke & Charlotte</h1>
            <p className="font-sans mb-4 tracking-[10px] font-extralight text-[#a300ff]">
                Invite you to join them
            </p>
            <p className="text-3xl font-bold mb-[60px] text-[#476a4c] tracking-widest">
                Monday, 14th April 2025 1.30pm<br/>Evening Guests arrive at 7.00pm
            </p>
            <Link to="/rsvp" className="border-2 py-2 px-8 flex border-[#a300ff] text-[#a300ff] tracking-widest font-extralight">RSVP</Link>
        </div>
    );
}

export default Welcome;
