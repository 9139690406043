// src/pages/Schedule.js
import React from "react";

function Schedule() {
    return (
        <div className="text-center justify-center content-center h-screen">
            <h1 className="text-7xl font-bold mb-4 text-[#476a4c] mb-[50px]">Wedding Schedule</h1>
            <ul className="text-lg">
                <li className="mb-2">1pm - Guests Arrive</li>
                <li className="mb-2">1.30pm - We Say “I Do”</li>
                <li className="mb-2"> 2.15pm -  Canapés & Photographs</li>
                <li className="mb-2">4pm - Wedding Breakfast</li>
                <li className="mb-2">7pm - Disco & Evening Guests Arrive</li>
                <li className="mb-2">Midnight - Carriages</li>
            </ul>
        </div>
    );
}

export default Schedule;
